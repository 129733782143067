import React from 'react'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import FacebookIcon from '@material-ui/icons/Facebook'
// const SocialShare = [
//   {
//     iconName: 'facebook',
//     link: 'https://www.facebook.com/',
//   },
//   { iconName: 'twitter', link: 'https://twitter.com/' },
//   {
//     iconName: 'instagram',
//     link: 'https://www.instagram.com/',
//   },
//   // { iconName: "dribbble", link: "https://dribbble.com/" },
//   {
//     iconName: 'tik-tok',
//     link: 'https://www.tiktok.com/',
//   },
// ]
const socialShare = [
  {
    iconName: 'github',
    IconComponent: GitHubIcon,
    link: 'https://github.com/junyi-peng-kr',
  },
  {
    iconName: 'linkedIn',
    IconComponent: LinkedInIcon,
    link: 'https://www.linkedin.com/in/jpeng08/',
  },
  {
    iconName: 'facebook',
    IconComponent: FacebookIcon,
    link: 'https://www.facebook.com/yzpjy/',
  },
]
const Social = () => {
  return (
    <>
      <ul className="social">
        {socialShare.map((val) => (
          <li key={val.iconName}>
            <a href={`${val.link}`} target="_blank" rel="noreferrer">
              <val.IconComponent fontSize="small" />
            </a>
          </li>
        ))}
      </ul>
      {/* END SOCIAL */}
    </>
  )
}

export default Social
